import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { spacing, fixedSpacing, maxScreenSize, minScreenSize, minImageWidth } from '../../lib/theme';
import { getPodcastImgSize, convertToSlug, domainName, podcastSlugUrl, podcastHrefUrl } from '../../lib/utils';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// import SubscribeOption from './SubscribeOption';

class Podcast extends React.Component {
  // loadPage = () => {
  //   message.loading('Loading...', 0);
  //   // Dismiss manually and asynchronously
  //   // setTimeout(hide, 2500);
  // }

  // componentWillUnmount() {
  //   message.destroy();
  // }

  // saveSearchBool = (podcastFeedUrl) => {
  //   const { fromSearch } = this.props;
  //   const fromSearchBool = fromSearch ? true : false;
  //   if (fromSearchBool) {
  //     console.dir('in searchBool');
  //     const searchBool = { feedUrl: podcastFeedUrl, time: new Date().getTime() };
  //     localStorage.setItem('lastSearchBool', JSON.stringify(searchBool));
  //   }
  // }

  podDesc = (podcast) => {
    if (!podcast || !podcast.description) {
      return '';
    }
    if (podcast.description.length < 100) {
      return podcast.description;
    }
    return podcast.description.slice(0, 100);
  }

  // const podcast = (props) => {
  render() {
    const { podcast, showSubscribe } = this.props;
    const podcastId = podcast.id || podcast.collectionId;

    const itunesRawId = podcast.collectionId || '';
    // console.dir(podcast.feedUrl);
    const podcastFeedUrl = podcast.feedUrl && podcast.feedUrl.split('?limit')[0];
    // console.dir(podcastFeedUrl);
    return (
      <div className='post-item'>
        {/* <Link prefetch href={`/podcast/${convertToSlug(podcast.collectionName)}?feed=${podcastFeedUrl}&id=${itunesRawId}&s=${searchBool}`} > */}
        {/* <Link prefetch as={`/podcast/${convertToSlug(podcast.collectionName)}?feed=${podcastFeedUrl}&PzID=${itunesRawId}`}
          href={`/podcast?feed=${podcastFeedUrl}&PzID=${itunesRawId}`} > */}
        {/* <a href={`${domainName}podcast/${convertToSlug(podcast.collectionName)}?feed=${podcastFeedUrl}&PzID=${itunesRawId}`}> */}
        <Link as={podcastSlugUrl(podcast.collectionName, itunesRawId)}
          href={podcastHrefUrl(itunesRawId)} >
          {/* <Link prefetch href={`/podcast?feed=${podcastFeedUrl}&id=${itunesRawId}&s=${searchBool}`} > */}
          <a>
            {/* <div className='post-item' onClick={this.props.onClick}> */}
            <div className='podcast-img'>
              <LazyLoadImage
                alt={`Image of ${podcast.collectionName || ''} podcast`}
                // height={'auto'}
                src={getPodcastImgSize(podcast.artworkUrl600, 'lg')} // use normal <img> attributes as props
                width={'100%'}
              // className='podcast-img'
              />
            </div>
            <h4 className='title'>{podcast.collectionName}</h4>
            <h5 className='title'>{podcast.artistName}&#58; {this.podDesc(podcast.description)}</h5>

            {/* <img src={getPodcastImgSize(podcast.artworkUrl600, 'default')} alt={`Image of ${podcast.collectionName || ''} podcast`} /> */}
            <div className='details'>
              {/* <Tooltip title={podcast.collectionName}> */}
              {/* <h5 className='title'>{podcast.collectionName}</h5> */}
              {/* </Tooltip> */}
              {/* <Tooltip title={podcast.artistName}> */}
              {/* <h5 style={{ fontStyle: 'italic' }} className='name'>{podcast.artistName}</h5> */}
              {/* </Tooltip> */}
              {/* {podcast.primaryGenreName} */}
              {/* {podcast.trackCount && <span>Episodes - {podcast.trackCount}
              <br /></span>} */}
              {/* {showSubscribe && userDetails && !subscribed && <Button onClick={(event) => { this.onSubscribeClick(event, userDetails.uid, podcast) }}>SUBSCRIBE</Button>}
              {showSubscribe && userDetails && subscribed && */}
              {/* {showSubscribe && <SubscribeOption itunesId={podcastId} podcast={podcast} showSubscribers={false} />} */}
              {/* Tags - {podcast.genres !== undefined ? podcast.genres.map((genre) => (
          <p> {genre} </p>
        )) : ''} */}
            </div>
          </a>
          {/* </Link> */}
        </Link>
        <style jsx>
          {`
            .podcast-img {
              // max-width: 200px;
              display: flex;
              min-height: 120px; // so that images load better.
              min-width: 115px; ${minImageWidth};
              height: 8vw;
              width: 8vw;
              border-radius: ${spacing('xs')};
              // box-shadow: 0 8px 8px rgba(0,0,0,0.30), 0 4px 4px rgba(0,0,0,0.22);
              box-shadow: 0 1px 2px 0 rgba(0,11,40,.14);
            }
           .post-item{
              // width: 8vw;
              min-width: 90px; // 80px;
              cursor: pointer;
              display: inline-block;
              max-width: 300px;
              margin-right: calc(3.5 * ${fixedSpacing()});
              margin-bottom: calc(2 * ${fixedSpacing()});
              // flex-basis: 15%;
           }
           .details {
              max-width: 160px;
              text-align: center;
            //  padding-left: ${spacing('md')};
           }
           .title {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 100px;
              margin-top: ${spacing('xs')};
              text-align: left;
           }
           .name {
              max-width: 180px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              line-height: 18px;     /* fallback */
              height: 36px;      /* fallback */
              -webkit-line-clamp: 2; /* number of lines to show */
              -webkit-box-orient: vertical;
           }
           @media ${maxScreenSize.tablet} {
            .podcast-img{
                // width: 10vw;
                border-radius: ${spacing('xs')};
                min-width: 0;
                min-height: 0;
                width: 25vw;
                height: 25vw;
            }
            .post-item{
              margin-left: ${spacing('xs')};
              margin-right: ${spacing('xs')}; // calc(1.25 * ${spacing()});
              margin-bottom: ${spacing('md')};
            }
          }
          @media ${minScreenSize.tablet} and ${maxScreenSize.laptop} {
            .post-item{
              margin-left: ${spacing('sm')};
              margin-right: ${spacing('sm')}; // calc(1.25 * ${spacing()});
              margin-bottom: ${spacing('md')};
            }
            .podcast-img{
                // width: 10vw;
                border-radius: ${spacing('xs')};
                min-width: 0;
                min-height: 0;
                width: 20vw;
                height: 20vw;
            }
          }
          //  @media ${maxScreenSize.tablet} {
          //   .post-item{
          //     margin-left: ${spacing('xs')};
          //     margin-right: ${spacing('xs')}; // calc(1.25 * ${spacing()});
          //     margin-bottom: ${spacing('md')};
          //   }
          // }
        `}
        </style>
      </div>
    )
  }
}

Podcast.propTypes = {
  // userDetails: PropTypes.object,
  podcast: PropTypes.object,
  settingsState: PropTypes.object,
  showSubscribe: PropTypes.bool,
  // fromSearch: PropTypes.bool,
  // subscribePodcast: PropTypes.func,
  // unsubscribePodcast: PropTypes.func,
}

// const mapStateToProps = (state) => {
//   return {
//     settingsState: state.settingsState,
//   }
// }

// export default connect(mapStateToProps, null)(Podcast)
export default Podcast;