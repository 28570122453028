import React, { Component } from 'react'
import PropTypes from 'prop-types';
import { staticAssetsPrefix } from '../../lib/utils';

export default class Testimonial extends Component {
    render() {
        const { author, comment } = this.props;
        return (
            <div>
                <div className='card'>
                    <div className='author'>
                        <img loading="lazy" src={`${staticAssetsPrefix}/static/images/testimonials/${author.img}`} />
                        <h4 className='serif-bold-extra'>{author.name}</h4>
                    </div>
                    <p className='serif' style={{ textAlign: 'left' }}>{comment}</p>
                </div>
                <style jsx>
                    {`
                        .card {
                            box-shadow: 0 20px 40px 0 rgba(0,11,40,.14);
                            margin: 1em;
                            padding: 1.5em 3em;
                            width: 300px;
                            display: inline-block;
                            border-radius: 12px;
                            background: #ffffffaa;
                        }
                        .author {
                            display: flex;
                            align-items: center;
                            margin-bottom: 1em;
                        }
                        img {
                            border-radius: 50%;
                            width: 48px;
                            margin-right: 12px;
                        }
                    `}
                </style>
            </div>
        )
    }
}

Testimonial.propTypes = {
    author: PropTypes.object,
    comment: PropTypes.string
}