import { Col, Row } from 'antd'
import React, { Component } from 'react'
import { staticAssetsPrefix } from '../../lib/utils'
import FeatureCard from '../MainPage/FeatureCard'
import { colors } from '../../lib/theme';


const features = [{ id: 1, title: 'Unlimited devices', message: 'Free sync between unlimited devices', icon: 'heart', iconColor: '', iconBg: 'hsla(337, 87%, 80%,1)' },
{ id: 2, title: 'Search', message: 'Search not just by podcasts but also by episodes', icon: 'search', iconColor: `${colors.primary}`, iconBg: `${colors.primaryDim}` },
{ id: 3, title: 'Favourites', message: 'Sort your favourites by ratings', icon: 'heart', iconColor: 'hsla(337, 87%, 50%,1)', iconBg: 'hsla(337, 87%, 80%,1)' },
{ id: 31, title: 'Bookmarks', message: 'Bookmark within episodes', icon: 'bookmark', iconColor: 'hsla(337, 87%, 50%,1)', iconBg: 'hsla(337, 87%, 80%,1)' },
{ id: 4, title: 'Multiple Playlists', message: 'Create unlimited playlists for each mood', icon: 'list-ul', iconColor: `${colors.primary}`, iconBg: `${colors.primaryDim}` },
{ id: 41, title: 'Auto Playlists', message: 'Automatically add episodes to playlist', icon: 'list-ul', iconColor: `${colors.primary}`, iconBg: `${colors.primaryDim}` },
{ id: 5, title: 'Offline', message: 'Download and listen offline on any mobile device', icon: 'headphones', iconColor: 'hsla(337, 87%, 50%,1)', iconBg: 'hsla(337, 87%, 80%,1)' },
{ id: 6, title: 'Personal', message: 'Keep track of your listening patterns', icon: 'chart-line', iconColor: `${colors.primary}`, iconBg: `${colors.primaryDim}` },
{ id: 71, title: 'Folders | Tags', message: 'Organize into podcasts into folders | tags', icon: 'folder', iconColor: `hsla(337, 87%, 50%,1)`, iconBg: `hsla(337, 87%, 80%,1)` },
{ id: 8, title: 'Private files', message: 'Upload your private audio files', icon: 'folder-open', iconColor: `hsla(337, 87%, 50%,1)`, iconBg: `hsla(337, 87%, 80%,1)` },
{ id: 9, title: 'Play Trailers', message: 'Play episode trailer before full audio', icon: 'play', iconColor: `${colors.primary}`, iconBg: `${colors.primaryDim}` },
{ id: 10, title: 'Audio Settings', message: 'Volume boost and trim silence', icon: 'sliders-h', iconColor: `${colors.primary}`, iconBg: `${colors.primaryDim}` },
    // { id: 7, title: 'No ads', message: 'Listen without ads', icon: 'heart', iconColor: 'red', iconBg: 'hsla(0, 87%, 80%,1)' },
];

export default class LandingFeatures extends Component {

    constructor(props) {
        super(props);
        this.state = { isVideoPaused: true };
    }
    componentDidMount() {
        for (const video of document.querySelectorAll('.video-features')) {
            video.controls = false
            video.addEventListener('mouseover', () => { video.controls = 'controls' })
            video.addEventListener('mouseout', () => { video.controls = false })
            video.addEventListener('pause', () => { this.setState({ isVideoPaused: true }) })
            video.addEventListener('play', () => { this.setState({ isVideoPaused: false }) })
        }
    }

    play = () => {
        for (const video of document.querySelectorAll('.video-features')) {
            video.play();
        }
    }

    componentWillUnmount() {
        for (const video of document.querySelectorAll('.video-features')) {
            // video.removeEventListener('mouseover')
            // video.removeEventListener('mouseout')
        }
    }
    render() {
        const { isVideoPaused } = this.state;
        return (
            <div>
                <Row style={{ padding: `0 0 0 4vw` }}>
                    <Col md={9} style={{ background: 'white' }}>
                        <div className='feature-card-div'>
                            {/* <h1> Device features</h1> */}
                            {features.slice(0, 6).map(feature => (
                                <article className='feature-card' key={feature.id}>
                                    <FeatureCard key={feature.id} id={feature.id} title={feature.title} message={feature.message}
                                        icon={feature.icon} iconColor={feature.iconColor} iconBg={feature.iconBg} />
                                </article>
                            ))}
                        </div>
                    </Col>
                    <Col md={6} style={{}}>
                        <div style={{ margin: '1.25em auto', textAlign: 'center', position: 'relative' }}>
                            <video className='video-features' width="100%" controls preload="metadata">
                                <source src="https://audio-us.b-cdn.net/Podurama_new_886_features.mp4#t=0.2" type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            {isVideoPaused && <div className='video-overlay'>
                                {/* <FontAwesomeIcon icon='play-circle' style={{ fontSize: '64px', color: '#F42A79' }} /> */}
                                <img onClick={this.play} style={{ maxWidth: '100%' }} src={`${staticAssetsPrefix}/static/images/play-button.png`} />
                            </div>}
                        </div>
                    </Col>
                    <Col md={9} style={{ background: 'white' }}>
                        <div className='feature-card-div'>
                            {/* <h1> Device features</h1> */}
                            {features.slice(6).map(feature => (
                                <article className='feature-card' key={feature.id}>
                                    <FeatureCard key={feature.id} id={feature.id} title={feature.title} message={feature.message}
                                        icon={feature.icon} iconColor={feature.iconColor} iconBg={feature.iconBg} />
                                </article>
                            ))}
                        </div>
                    </Col>
                    {/* <Col className='all-devices' xl={11} style={{ textAlign: 'center', backgroundImage: `url(${staticAssetsPrefix}/static/images/home/bg-all.svg)`, paddingRight: '2vw' }}>
                        <picture>
                            <source srcSet={`${staticAssetsPrefix}/static/images/main-page/multiple-devices-mobile.jpg`} media="(max-width: 768px)" />
                            <img className='device-img' src={`${staticAssetsPrefix}/static/images/main-page/multiple-devices-main-no-bg.png`} loading="lazy" alt='Image of podcast app on different devices' />
                        </picture>
                        <h4 className='serif' style={{ transform: 'translate(0, -20%)' }}>Available for iOS, Android, Web, Windows and MacOS.</h4>
                    </Col> */}
                </Row>
                <style jsx>
                    {`
                    .video-features {
                        border-radius: 12px;
                    }
                    .video-overlay {
                        position: absolute;
                        left: calc(50% - 50px);
                        top: calc(50% - 50px);
                        width: 100px;
                    }
                    .feature-card-div {
                        display: flex;
                        flex-wrap: wrap;
                    }
                    .feature-card {
                        // display: inline-block;
                        width: 50%;
                    }
                    `}
                </style>
            </div>
        )
    }
}
